var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"tender-list"}},[_c('v-card',[_c('StandardCardHeader',{attrs:{"go-to-link":'admin-user-create',"title":'User list',"button-text":'Create',"show-action-button":false,"link-button-color":'success'}}),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"tender-search mb-4",attrs:{"placeholder":_vm.$t('Type to search'),"clearable":"","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.getDataFromApi,"click:clear":function($event){$event.preventDefault();return _vm.resetSearch()}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{staticClass:"mb-4",attrs:{"placeholder":_vm.$t('Status filter'),"items":_vm.statusOptions,"item-value":"key","item-text":function (item) { return _vm.$t(item.name); },"outlined":"","dense":"","clearable":"","hide-details":""},on:{"change":_vm.getDataFromApi},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{staticClass:"mb-4",attrs:{"placeholder":_vm.$t('Location filter'),"items":_vm.$store.getters.getStocks,"item-value":"id","item-text":"name","outlined":"","dense":"","clearable":"","hide-details":""},on:{"change":_vm.getDataFromApi},model:{value:(_vm.locationFilter),callback:function ($$v) {_vm.locationFilter=$$v},expression:"locationFilter"}})],1)],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.tableColumns,"items":_vm.userListTable,"items-per-page":_vm.getItemsPerPageFromLS(),"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"no-results-text":_vm.$t('noResults'),"no-data-text":_vm.$t('noResults'),"footer-props":{
        'items-per-page-text': '#',
        'items-per-page-options':[10, 20, 50, 100],
        'page-text': ("{0}-{1} " + (_vm.$t('of')) + " {2}")
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'admin-user-edit', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.first_name)+" ")])],1)])]}},{key:"item.last_name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'admin-user-edit', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.last_name)+" ")])],1)])]}},{key:"item.company.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.company.name)+" ")])])]}},{key:"item.email",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'admin-user-edit', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.email)+" ")])],1)])]}},{key:"item.phone",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.phone))])])]}},{key:"item.location",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.location ? _vm.getUserLocation(item) : '')+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(_vm.$t(item.status))+" ")])]}},{key:"item.user_type",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip font-weight-semibold text-capitalize",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$t(item.user_type))+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{name:'admin-user-edit',params:{id:item.id}},"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentEditOutline)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('Edit')))])],1)],1),(!item.deleted_at && item.email !== 'office@sistemcd.rs')?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){$event.preventDefault();return _vm.deleteUser(item.id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('Delete')))])],1)],1):_vm._e(),(item.deleted_at)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){$event.preventDefault();return _vm.restoreUser(item.id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileRestoreOutline)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('Restore')))])],1)],1):_vm._e()],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }